export const numberWithCommas = (x, isZeroHide) => {
  return x
    ? String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : isZeroHide
    ? ''
    : 0;
};

export const roomTypeFormat = (roomType, isStatus) => {
  if (roomType === '5000man' || roomType === '10eok') {
    if (isStatus && roomType == '10eok') {
      roomType = '10억';
    } else {
      roomType = '5천만';
    }
  } else if (roomType === '2eok') {
    roomType = '2억';
  } else if (roomType === '5eok') {
    roomType = '5억';
  }
  return roomType;
};

export const totalFormat = (total) => {
  let totalStr = String(total);
  let val = '';
  let idx = 0;

  if (totalStr.length >= 13) {
    val += totalStr.substring(idx, totalStr.length - 12) + '조 ';
    totalStr = totalStr.substring(totalStr.length - 12);
  }
  if (totalStr.length >= 9) {
    val += totalStr.substring(idx, totalStr.length - 8) + '억 ';
    totalStr = totalStr.substring(totalStr.length - 8);
  }
  if (totalStr.length >= 5) {
    val += totalStr.substring(idx, totalStr.length - 4) + '만 ';
    totalStr = totalStr.substring(totalStr.length - 4);
  }
  return val;
};
