import { useEffect, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import useStore from '@stores/useStore';

import Login from './components/Login';
import Status from './components/Status';
import StatusDaily from './components/StatusDaily';
import Report from './components/Report';

import { maskOn, maskOff } from '@utils/mask/index';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@utils/mask/mask.css';

const Wrap = styled.div`
  width: 100%;

  .content-wrap {
    width: 100%;
    padding: 20px;

    table {
      th,
      td {
        text-align: center;
      }
    }
  }
`;

const App = observer(() => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    tab: 'status',
  }));

  const handleTab = useCallback((tab) => {
    state.tab = tab;
  }, []);

  const handleResize = useCallback(() => {
    commonStore.screen.sm = false;
    commonStore.screen.md = false;
    commonStore.screen.lg = false;
    commonStore.screen.xl = false;
    commonStore.screen.xxl = false;

    if (window.innerWidth >= 0) {
      commonStore.screen.xs = true;
    }
    if (window.innerWidth >= 576) {
      commonStore.screen.sm = true;
    }
    if (window.innerWidth >= 768) {
      commonStore.screen.md = true;
    }
    if (window.innerWidth >= 992) {
      commonStore.screen.lg = true;
    }
    if (window.innerWidth >= 1200) {
      commonStore.screen.xl = true;
    }
    if (window.innerWidth >= 1600) {
      commonStore.screen.xxl = true;
    }
  }, []);

  useEffect(() => {
    commonStore.connectSocket();

    window.maskOn = maskOn;
    window.maskOff = maskOff;

    if (Cookies.get('khan_auth') === 'true') {
      commonStore.isLogin = true;
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrap>
      {commonStore.isLogin ? (
        <div className="content-wrap">
          <Tabs activeKey={state.tab} onSelect={handleTab} className="mb-3">
            <Tab eventKey="status" title="리스트">
              <Status tab={state.tab} />
            </Tab>
            <Tab eventKey="status_daily" title="일자별">
              <StatusDaily tab={state.tab} />
            </Tab>
            <Tab eventKey="report" title="승률">
              <Report tab={state.tab} />
            </Tab>
          </Tabs>
        </div>
      ) : (
        <Login />
      )}
    </Wrap>
  );
});

export default App;
