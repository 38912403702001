import { numberWithCommas, roomTypeFormat } from '@utils/common';
import { toJS } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  .container {
    max-width: 100%;
  }
`;

const Report = observer(({ tab }) => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    list: [[], [], []],
  }));

  const renderTable = useCallback((data) => {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>방타입</th>
            <th>카드</th>
            <th>총Round</th>
            <th>WIN</th>
            <th>LOSE</th>
            <th>승률</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            let per = (item.win / (item.win + item.lose)) * 100;
            if (isNaN(per)) {
              per = 0;
            }

            return (
              <tr key={item._id}>
                <td>{roomTypeFormat(item.roomType)}</td>
                <td
                  className={`color ${
                    per === 0 ? '' : per >= 50 ? 'red bold' : 'blue bold'
                  }`}
                >
                  {item.cardName}
                </td>
                <td>{numberWithCommas(item.totalRound)}</td>
                <td className={`color ${item.win > 0 ? 'red' : ''}`}>
                  {numberWithCommas(item.win)}
                </td>
                <td className={`color ${item.lose > 0 ? 'blue' : ''}`}>
                  {numberWithCommas(item.lose)}
                </td>
                <td
                  className={`color ${
                    per === 0 ? '' : per >= 50 ? 'red' : 'blue'
                  }`}
                >
                  {per.toFixed(2)}%
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }, []);

  const renderData = useCallback(() => {
    let value = '';
    if (commonStore.screen.xl) {
      value = (
        <Row>
          {toJS(state.list).map((data, idx) => (
            <Col key={`room_${idx}`} xs={12} md={6} xl={4}>
              {renderTable(data)}
            </Col>
          ))}
        </Row>
      );
    } else {
      value = (
        <Accordion>
          {toJS(state.list).map((data, idx) => (
            <Accordion.Item
              eventKey={`reportAccordion_${idx}`}
              key={`room_${idx}`}
            >
              <Accordion.Header>
                <div>{data[0] ? roomTypeFormat(data[0].roomType) : ''}</div>
              </Accordion.Header>
              <Accordion.Body>{renderTable(data)}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      );
    }

    return value;
  }, []);

  useEffect(() => {
    if (commonStore.socket && tab === 'report') {
      window.maskOn();
      commonStore.socket.emit('get report');
      commonStore.socket.on('khan report', (data) => {
        window.maskOn();
        data = JSON.parse(JSON.stringify(data));
        const val = [];
        val.push(
          data
            // .filter((item) => item.roomType === '5000man')
            .filter((item) => item.roomType === '10eok')
            .map((item) => {
              if (item.cardName[1] == item.cardName[2]) {
                item.cardName = item.cardName.replace('o', '');
                item.cardName = item.cardName.toUpperCase();
              } else {
                item.cardName = item.cardName.toUpperCase();
                if (item.cardName[0] === 'S') {
                  item.cardName += 's';
                } else {
                  item.cardName += 'o';
                }
                item.cardName = item.cardName.substring(1);
              }
              return item;
            })
            .sort(function (a, b) {
              let aPer = (a.win / (a.win + a.lose)) * 100;
              let bPer = (b.win / (b.win + b.lose)) * 100;
              if (isNaN(aPer)) {
                aPer = 0;
              }
              if (isNaN(bPer)) {
                bPer = 0;
              }
              return bPer - aPer || a.cardName.localeCompare(b.cardName);
              // return a.cardName.localeCompare(b.cardName);
            }),
        );
        val.push(
          data
            .filter((item) => item.roomType === '2eok')
            .map((item) => {
              if (item.cardName[1] == item.cardName[2]) {
                item.cardName = item.cardName.replace('o', '');
                item.cardName = item.cardName.toUpperCase();
              } else {
                item.cardName = item.cardName.toUpperCase();
                if (item.cardName[0] === 'S') {
                  item.cardName += 's';
                } else {
                  item.cardName += 'o';
                }
                item.cardName = item.cardName.substring(1);
              }
              return item;
            })
            .sort(function (a, b) {
              let aPer = (a.win / (a.win + a.lose)) * 100;
              let bPer = (b.win / (b.win + b.lose)) * 100;
              if (isNaN(aPer)) {
                aPer = 0;
              }
              if (isNaN(bPer)) {
                bPer = 0;
              }
              return bPer - aPer || a.cardName.localeCompare(b.cardName);
              // return a.cardName.localeCompare(b.cardName);
            }),
        );
        val.push(
          data
            .filter((item) => item.roomType === '5eok')
            .map((item) => {
              if (item.cardName[1] == item.cardName[2]) {
                item.cardName = item.cardName.replace('o', '');
                item.cardName = item.cardName.toUpperCase();
              } else {
                item.cardName = item.cardName.toUpperCase();
                if (item.cardName[0] === 'S') {
                  item.cardName += 's';
                } else {
                  item.cardName += 'o';
                }
                item.cardName = item.cardName.substring(1);
              }
              return item;
            })
            .sort(function (a, b) {
              let aPer = (a.win / (a.win + a.lose)) * 100;
              let bPer = (b.win / (b.win + b.lose)) * 100;
              if (isNaN(aPer)) {
                aPer = 0;
              }
              if (isNaN(bPer)) {
                bPer = 0;
              }
              return bPer - aPer || a.cardName.localeCompare(b.cardName);
              // return a.cardName.localeCompare(b.cardName);
            }),
        );
        state.list = val;
        window.maskOff();
      });
    }
  }, [commonStore.socket, tab]);

  return (
    <Wrap>
      <Container>{renderData()}</Container>
    </Wrap>
  );
});

export default Report;
