import { useEffect, handleRemove, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import { toJS } from 'mobx';
import dayjs from 'dayjs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import Accordion from 'react-bootstrap/Accordion';

import useStore from '@stores/useStore';

import { totalFormat } from '@utils/common';

const Wrap = styled.div`
  .container {
    max-width: 100%;
  }
  .bg-gray {
    background-color: #f0f0f0;
    * {
      background-color: #f0f0f0;
    }
  }
  .total-amount {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .search-row {
    align-items: center;
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')` 
  .search-row > div {
    margin-bottom: 10px;
  }
  `}
`;

const StatusDaily = observer(({ tab }) => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    dailyList: [],
    totalAmount: 0,

    startDate: dayjs().subtract(7, 'days').toDate(),
    endDate: dayjs().toDate(),
  }));

  const handleDate = useCallback(
    (key) => (date) => {
      state[key] = date;
    },
    [],
  );
  const handleSearch = useCallback(() => {
    const sDate = dayjs(state.startDate).startOf('date');
    const eDate = dayjs(state.endDate).endOf('date');
    if (sDate.format('YYYY-MM-DD') > eDate.format('YYYY-MM-DD')) {
      return window.alert('시작날짜 이후로 종료날짜를 선택하세요');
    }
    if (sDate.format('YYYY-MM-DD') === eDate.format('YYYY-MM-DD')) {
      return window.alert('시작날짜와 종료날짜를 다른 날로 선택하세요');
    }

    window.maskOn();
    commonStore.socket.emit('get status daily', {
      sDate: sDate.toDate(),
      eDate: eDate.toDate(),
    });
  }, []);

  const renderData = useCallback(() => {
    let value = '';

    if (commonStore.screen.xl) {
      value = (
        <Row>
          {toJS(state.dailyList).map((dailyData, idx) => (
            <Col key={`daily_data_${idx}`} xs={12} md={6} xl={3}>
              <Table bordered hover size="sm">
                <thead>
                  <tr className="bg-gray">
                    <th colSpan={3}>{dailyData.date}</th>
                  </tr>
                  <tr>
                    <th>이름</th>
                    <th>금고</th>
                  </tr>
                </thead>
                <tbody>
                  {dailyData.list.map((item) => (
                    <tr key={item._id}>
                      <td>{item.appName}</td>
                      <td>{item.endMoneyStr}</td>
                    </tr>
                  ))}
                  <tr className="bg-gray">
                    <td className="bold">총합</td>
                    <td className="bold">{totalFormat(dailyData.amount)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          ))}
        </Row>
      );
    } else {
      value = (
        <Accordion>
          {toJS(state.dailyList).map((dailyData, idx) => (
            <Accordion.Item
              eventKey={`dailyAccordion${idx}`}
              key={`daily_data_${idx}`}
            >
              <Accordion.Header>
                <div>{dailyData.date}</div>
                <div>{totalFormat(dailyData.amount)}</div>
              </Accordion.Header>
              <Accordion.Body>
                <Table bordered hover size="sm">
                  <thead>
                    <tr className="bg-gray">
                      <th colSpan={3}>{dailyData.date}</th>
                    </tr>
                    <tr>
                      <th>이름</th>
                      <th>금고</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dailyData.list.map((item) => (
                      <tr key={item._id}>
                        <td>{item.appName}</td>
                        <td>{item.endMoneyStr}</td>
                      </tr>
                    ))}
                    <tr className="bg-gray">
                      <td className="bold">총합</td>
                      <td className="bold">{totalFormat(dailyData.amount)}</td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      );
    }
    return value;
  }, []);

  useEffect(() => {
    if (commonStore.socket && tab === 'status_daily') {
      window.maskOn();
      const sDate = dayjs(state.startDate).startOf('date').toDate();
      const eDate = dayjs(state.endDate).endOf('date').toDate();
      commonStore.socket.emit('get status daily', { sDate, eDate });
      commonStore.socket.on('khan status daily', (data) => {
        window.maskOn();
        data = JSON.parse(JSON.stringify(data));
        const dailyList = [];
        let totalAmount = 0;
        for (const items of data) {
          if (!items[0]) {
            continue;
          }
          const dailyData = {
            date: dayjs(items[0].createdAt).format('YYYY-MM-DD'),
            amount: 0,
            list: [],
          };

          dailyData.list = items
            .map((item) => {
              dailyData.amount += item.endMoney || 0;
              return item;
            })
            .sort(function (a, b) {
              const aNum = a.appName.replace(/_\d$/, '').replace(/[^\d]/g, '');
              const aChildNum = a.appName.replace(/.+\d_/, '');
              const bNum = b.appName.replace(/_\d$/, '').replace(/[^\d]/g, '');
              const bChildNum = b.appName.replace(/.+\d_/, '');
              return (
                parseInt(aNum) - parseInt(bNum) ||
                parseInt(aChildNum) - parseInt(bChildNum)
              );
            });

          dailyList.push(dailyData);
          totalAmount += dailyData.amount || 0;
        }
        state.dailyList = dailyList;
        state.totalAmount = totalAmount;
        window.maskOff();
      });
    }
  }, [commonStore.socket, tab]);

  return (
    <Wrap>
      <Container>
        <Card className="mb-4">
          <Card.Body>
            <Row className="search-row">
              <Col xs={12} sm={6} xl={2}>
                시작날짜 :{' '}
                <DatePicker
                  selected={state.startDate}
                  onChange={handleDate('startDate')}
                />
              </Col>
              <Col xs={12} sm={6} xl={2}>
                종료날짜 :{' '}
                <DatePicker
                  selected={state.endDate}
                  onChange={handleDate('endDate')}
                />
              </Col>
              <Col xs={12} xl={2}>
                <div className="d-grid">
                  <Button variant="primary" onClick={handleSearch}>
                    검색
                  </Button>
                </div>
              </Col>
              <Col xs={12} xl={6}>
                <div className="total-amount">
                  총합 : {totalFormat(state.totalAmount)}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {renderData()}
      </Container>
    </Wrap>
  );
});

export default StatusDaily;
