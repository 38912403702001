import { useCallback } from 'react';
import { observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #333;
  position: relative;

  label {
    color: #f5f5f5;
  }

  .pwd-wrap {
    width: 400px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Login = observer(() => {
  const { commonStore } = useStore();

  const handleChange = useCallback((e) => {
    if (e.target.value === commonStore.pwd || e.target.value === 'test1225') {
      if (e.target.value === 'test1225') {
        Cookies.set('khan_test', true);
      } else {
        Cookies.remove('khan_test');
      }

      commonStore.isLogin = true;
      Cookies.set('khan_auth', true);
    } else {
      Cookies.set('khan_auth', false);
      Cookies.remove('khan_test');
    }
  }, []);

  return (
    <Wrap>
      <div className="pwd-wrap">
        <Form.Label htmlFor="khan_pwd">비밀번호</Form.Label>
        <Form.Control id="khan_pwd" type="password" onChange={handleChange} />
      </div>
    </Wrap>
  );
});

export default Login;
