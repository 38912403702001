import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html, body {  
  overscroll-behavior-x: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "Noto Sans Korean", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

body {
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.primary};
    }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.color {
  &.primary {
    color: #4e6b9e;
  }
  &.secondary {
    color: #3b485f;
  }  
  &.dark {
    color: #6339e8;
  }  
  &.success {
    color: #2e7d32;
  }
  &.info {
    color: #0288d1;
  }
  &.warning {
    color: #ed6c02;
  }
  &.error {
    color: #ff1744;
  }
  &.red {
    color: #e00d06;
  }
  &.blue {
    color: #0050e0;
  }
  &.grey {
    color: #777;
  }
}

.bold {
  font-weight: bold;
}

.desktop {
  display: block !important;
}
tr.desktop {
  display: table-row !important;
}
.mobile {
  display: none !important;
}

.accordion-header {
  .accordion-button {
    position: relative;
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding-right: 35px;

    &::after {
      position: absolute;
      right: 5px;
    }
    & > div {
      font-size: 13px;
    }
    & > div:first-child {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.accordion-body {
  padding: 0;
}

${(props) => props.theme.breakpoint('xs', 'xl')`
  .desktop {
    display: none !important;
  }
  tr.desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }

  .status-total-mobile {
    table {
      table-layout:fixed;
      width:100%;
      text-align:center;
    }
    table th { 
      background:#ccc;
    }
    table td, .table th {
      padding:10px 20px;
      border-top:1px solid #ccc;
      word-break:break-all
    }
    table thead {
      display:none;
    }
    table tr {
      display:block;
      margin-bottom:10px;
      border-top:none;
    }
    table th, table td {
      display:block;
      position:relative;
      padding: 5px 0;
      padding-left: 32%;
      padding-right: 5px;
      border-width:0 0 1px 0;
    }

    table td:before {
      display:block;
      position:absolute;
      left:0;
      top:0;
      width: 30%;
      height: 100%;
      padding:5px 0;
      background:#ccc;
    }
    .total-table td:nth-child(1):before {content:'총합';}
    .total-table td:nth-child(2):before {content:'총Round';}
    .total-table td:nth-child(3):before {content:'총WIN';}
    .total-table td:nth-child(4):before {content:'총LOSE';}
  }
  .table {
    margin: 0;    
  }
  `}
`;

export default GlobalStyle;
