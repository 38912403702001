import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { toJS } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';

import useStore from '@stores/useStore';

import { numberWithCommas, roomTypeFormat, totalFormat } from '@utils/common';

const Wrap = styled.div`
  .memo {
    height: 35px;
  }
  table tr th,
  table tr td {
    vertical-align: middle;
  }

  .off-canvas-btn {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
  }

  .sum {
    font-size: 18px;
    font-weight: bold;

    td {
      padding: 10px 0;
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')`
  padding-bottom: 60px;
  
  table {
    table-layout:fixed;
    width:100%;
    text-align:center;
  }
  table th { 
    background:#ccc;
  }
  table td, table th {
    padding:10px 20px;
    border-top:1px solid #ccc;
    word-break:break-all    
  }
  table thead {
    display:none;
  }
  table tr {
    display:block;
    border-top:none;
    border-color: #e0e0e0;
  }
  table th, table td {
    display:block;
    position:relative;
    padding: 5px 0;
    padding-left: 32%;
    padding-right: 5px;
    border-width:0 0 1px 0;
    min-height: 32px;
  }

  table td:before {
    display:block;
    position:absolute;
    left:0;
    top:0;
    width: 30%;
    height: 100%;
    padding:5px 0;
    background:#ccc;
  }

  ${(props) =>
    props.istest
      ? `
  .list-table td:nth-child(1):before {content:'이름';}
  .list-table td:nth-child(2):before {content:'금고';}
  .list-table td:nth-child(3):before {content:'결과';}
  .list-table td:nth-child(4):before {content:'방타입';}
  .list-table td:nth-child(5):before {content:'카드셋';}
  .list-table td:nth-child(6):before {content:'히든';}
  .list-table td:nth-child(7):before {content:'마틴';}
  .list-table td:nth-child(8):before {content:'하프올인';}
  .list-table td:nth-child(9):before {content:'방이동';}
  .list-table td:nth-child(10):before {content:'블라인드';}
  .list-table td:nth-child(11):before {content:'최소금액';}
  .list-table td:nth-child(12):before {content:'최대금액';}  
  .list-table td:nth-child(13):before {content:'상태';}
  .list-table td:nth-child(14):before {content:'마지막활동';}
  .list-table td:nth-child(15):before {content:'메모';}
  .list-table td:nth-child(16):before {content:' ';}
  `
      : `
  .list-table td:nth-child(1):before {content:'이름';}
  .list-table td:nth-child(2):before {content:'금고';}
  .list-table td:nth-child(3):before {content:'방타입';}
  .list-table td:nth-child(4):before {content:'카드셋';}
  .list-table td:nth-child(5):before {content:'히든';}
  .list-table td:nth-child(6):before {content:'마틴';}
  .list-table td:nth-child(7):before {content:'하프올인';}
  .list-table td:nth-child(8):before {content:'방이동';}
  .list-table td:nth-child(9):before {content:'블라인드';}
  .list-table td:nth-child(10):before {content:'최소금액';}
  .list-table td:nth-child(11):before {content:'최대금액';}  
  .list-table td:nth-child(12):before {content:'상태';}
  .list-table td:nth-child(13):before {content:'마지막활동';}
  .list-table td:nth-child(14):before {content:'메모';}
  `}  

  .list-table {
    margin: 0;
  }
  `}
`;

const Status = observer(({ tab }) => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    list: [],
    total: 0,
    totalRound: 0,
    totalWin: 0,
    totalLose: 0,

    showOffCanvas: false,
    now: dayjs().subtract(10, 'minutes'),
  }));

  const handleRemove = useCallback(
    (item) => () => {
      if (window.confirm(`${item.appName} 데이터를 삭제 하시겠습니까?`)) {
        commonStore.socket.emit('remove status', item._id);
      }
    },
    [],
  );
  const handleStop = useCallback(
    (item) => () => {
      if (window.confirm(`${item.appName} 중지 하시겠습니까?`)) {
        commonStore.socket.emit('stop app', item._id);
      }
    },
    [],
  );

  const handleResizeMemo = useCallback((e) => {
    e.currentTarget.style.height = '35px'; //height 초기화
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  }, []);

  const handleSubmitMemo = useCallback(
    (_id) => (e) => {
      commonStore.socket.emit('set status item', {
        _id,
        data: {
          memo: e.target.value,
        },
      });
    },
    [],
  );
  const handleOffCanvas = useCallback(
    (visible) => (e) => {
      state.showOffCanvas = visible;
    },
    [],
  );

  const renderTr = useCallback((item, idx) => {
    return (
      <tr>
        <td>{item.appName}</td>
        <td>{item.totalStr}</td>
        {Cookies.get('khan_test') === 'true' ? (
          <td>{item.reportResult}</td>
        ) : null}
        <td>{roomTypeFormat(item.roomType, true)}방</td>
        <td>{item.cardSetting ? item.cardSetting.title : ''}방</td>
        <td>{item.hidden ? 'Y' : 'N'}</td>
        <td>{item.martin ? 'Y' : 'N'}</td>
        <td>{item.halfMax ? 'Y' : 'N'}</td>
        <td>{item.isMoveRoom ? 'Y' : 'N'}</td>
        <td>{item.isBlind ? 'Y' : 'N'}</td>
        <td>{item.withdrawMin}억</td>
        <td>{item.withdrawMax}억</td>
        <td
          className={`color ${
            item.status === '종료'
              ? 'red'
              : item.status === '진행중'
              ? 'success'
              : 'blue'
          }`}
        >
          {item.status}
        </td>
        <td className={dayjs(item.updatedAt) < state.now ? 'color error' : ''}>
          {dayjs(item.updatedAt).format('M월 D일 HH:mm')}
        </td>
        <td>
          <Form.Control
            id={`memo_${idx}`}
            as="textarea"
            className="memo"
            placeholder="메모"
            defaultValue={item.memo}
            onChange={handleResizeMemo}
            onBlur={handleSubmitMemo(item._id)}
          />
        </td>
        {Cookies.get('khan_test') === 'true' ? (
          <td>
            <Button variant="danger" onClick={handleStop(item)}>
              중지
            </Button>
          </td>
        ) : null}
        {/* <td>
          <Button variant="danger" onClick={handleRemove(item)}>삭제</Button>
        </td> */}
      </tr>
    );
  });

  const renderTbody = useCallback(() => {
    let value = '';

    if (commonStore.screen.xl) {
      value = (
        <Table className="list-table" striped bordered hover size="sm">
          <thead>
            <tr>
              <th>이름</th>
              <th>금고</th>
              {Cookies.get('khan_test') === 'true' ? <th>결과</th> : null}
              <th>방타입</th>
              <th>카드셋</th>
              <th>히든</th>
              <th>마틴</th>
              <th>
                하프
                <br />
                올인
              </th>
              <th>
                방
                <br />
                이동
              </th>
              <th>
                블라
                <br />
                인드
              </th>
              <th>
                최소
                <br />
                금액
              </th>
              <th>
                최대
                <br />
                금액
              </th>
              <th>상태</th>
              <th>마지막활동</th>
              <th>메모</th>
              {Cookies.get('khan_test') === 'true' ? <th></th> : null}
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            <tr className="desktop sum">
              <td>총합</td>
              <td>{totalFormat(state.total)}</td>
              <td colSpan={Cookies.get('khan_test') === 'true' ? 13 : 11}></td>
            </tr>
            {toJS(state.list).map((item, idx) => (
              <React.Fragment key={item._id}>
                {renderTr(item, idx)}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      );
    } else {
      value = (
        <Accordion>
          {toJS(state.list).map((item, idx) => (
            <Accordion.Item eventKey={`statusAccordion${idx}`} key={item._id}>
              <Accordion.Header>
                <div
                  className={`color ${
                    item.status === '종료'
                      ? 'red'
                      : item.status === '진행중'
                      ? 'success'
                      : 'blue'
                  }`}
                >
                  {item.appName}
                </div>
                {Cookies.get('khan_test') === 'true' ? (
                  <div>{item.reportResult}</div>
                ) : null}
                <div
                  className={
                    dayjs(item.updatedAt) < state.now ? 'color error' : ''
                  }
                >
                  {item.totalStr}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {
                  <Table
                    className="list-table"
                    striped
                    bordered
                    hover
                    size="sm"
                  >
                    <tbody>{renderTr(item, idx)}</tbody>
                  </Table>
                }
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      );
    }

    return value;
  }, []);

  useEffect(() => {
    if (commonStore.socket && tab === 'status') {
      window.maskOn();
      commonStore.socket.emit('get status');
      commonStore.socket.on('khan status', (data) => {
        window.maskOn();
        data = JSON.parse(JSON.stringify(data));
        state.now = dayjs().subtract(10, 'minutes');

        let total = 0;
        let totalRound = 0;
        let totalWin = 0;
        let totalLose = 0;

        const list = data.status.filter((item) => {
          return Cookies.get('khan_test') === 'true'
            ? item.isTest
            : !item.isTest;
        });
        // const now = dayjs().subtract(30, 'minutes');
        state.list = list
          .map((item) => {
            // const updatedAt = dayjs(item.updatedAt);
            // if (updatedAt >= now && item.status === '진행중') {
            //   total += item.total || 0;
            // } else {
            //   item.totalStr = '데이터없음';
            // }
            total += item.total || 0;
            totalRound += item.totalRound || 0;
            totalWin += item.win || 0;
            totalLose += item.lose || 0;

            return item;
          })
          .sort(function (a, b) {
            const aNum = a.appName.replace(/_\d$/, '').replace(/[^\d]/g, '');
            const aChildNum = a.appName.replace(/.+\d_/, '');
            const bNum = b.appName.replace(/_\d$/, '').replace(/[^\d]/g, '');
            const bChildNum = b.appName.replace(/.+\d_/, '');
            return (
              parseInt(aNum) - parseInt(bNum) ||
              parseInt(aChildNum) - parseInt(bChildNum)
            );
          });
        state.total = total;
        state.totalRound = totalRound;
        state.totalWin = totalWin;
        state.totalLose = totalLose;
        window.maskOff();

        setTimeout(() => {
          const elems = document.querySelectorAll('.memo');
          if (elems) {
            for (const elem of elems) {
              elem.style.height = '35px'; //height 초기화
              elem.style.height = elem.scrollHeight + 'px';
            }
          }
        }, 10);
      });

      commonStore.socket.on('updated status', (data) => {
        commonStore.socket.emit('get status');
      });
    }
  }, [commonStore.socket, tab]);

  return (
    <Wrap istest={Cookies.get('khan_test') === 'true' ? 1 : 0}>
      <Button
        className="mobile off-canvas-btn"
        variant="primary"
        size="lg"
        onClick={handleOffCanvas(!state.showOffCanvas)}
      >
        총합
      </Button>

      {renderTbody()}

      <Offcanvas
        className="status-total-mobile"
        show={state.showOffCanvas}
        onHide={handleOffCanvas(false)}
      >
        <Offcanvas.Body>
          <Table className="total-table" striped bordered hover size="sm">
            <thead>
              <th>총합</th>
              <th>총Round</th>
              <th>총WIN</th>
              <th>총LOSE</th>
            </thead>
            <tbody>
              <tr>
                <td>{totalFormat(state.total)}</td>
                <td>{numberWithCommas(state.totalRound)}회</td>
                <td>{numberWithCommas(state.totalWin)}회</td>
                <td>{numberWithCommas(state.totalLose)}회</td>
              </tr>
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>
    </Wrap>
  );
});

export default Status;
